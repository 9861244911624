var Environment = getEnvironment();


function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "TEST") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    } 
    const clientInfo = {
        "stage" : [
            { brand : "FF", country: 'SG', clientId: '4BE3CB34-8610-4389-8A34-81F90AC68B28' },
            { brand : "GR", country: 'SG',clientId: '0F4742AA-D2AC-4269-BDEC-AB83F3A918C8' }
        ],
        "production": [
            { brand : "FF", country: 'SG', clientId: '58BFCE17-01B6-4FCF-A759-7B5C8D23FC58' },
            { brand : "GR", country: 'SG',clientId: '17CBE749-F478-4725-BB71-CC6482F4857D' }
        ]
    }
    const SSO = {
        clientInfo:clientInfo[platform],
        OPServer: platform === 'stage' ? 'https://id.uat.circuithq.com/' : 'https://id.circuithq.com/',
        redirect_uri: '/callback',
        scope: 'openid profile member-data offline_access',
        userInfoEndpoint: 'connect/userinfo',
        extra: {prompt: 'consent', access_type: 'offline'}
    }
    var _Environments = {
        production: { SSO, BASE_URL: 'https://api.wexer.com/', API_KEY: 'a0ddf4dc5b0b4b628e5a4c27b93c988e',JWPLAYER:'95riWD5s.js', API_SECRET: '711ff01003764889987158b329c7e6c0' },
        stage: { SSO, BASE_URL: 'https://stage.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'95riWD5s.js', API_SECRET: '573572796441127398913495789868468206481' },
        test: { SSO, BASE_URL: 'https://ff-int.api.wexer.com/', API_KEY: '1234123412341234', JWPLAYER:'HofBP5zj.js',API_SECRET: '573572796441127398913495789868468206481' },
        development: { SSO, BASE_URL: "https://test.api.wexer.com/", API_KEY: '50703717552133197901571613251413029814',JWPLAYER:'HofBP5zj.js', API_SECRET: '51749401073929517144660310291806967949' },
        uat: { SSO, BASE_URL: 'https://uat.api.wexer.com/', API_KEY: '1234123412341234', JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
    
    }
    return _Environments[platform];
  }

module.exports = Environment 
 